import React, {useState} from 'react';

export const Accordion = ({items}) => {
    const [openIndices, setOpenIndices] = useState(new Set());

    const toggleAccordion = (index) => {
        setOpenIndices(prev => {
            const next = new Set(prev);
            next.has(index) ? next.delete(index) : next.add(index);
            return next;
        });
    };

    return (
        <div className="w-full">
            {items.map((item, index) => (
                <div key={index} className="mb-4 border-b border-gray-700">
                    <button
                        className="flex justify-between items-center w-full py-4 text-left bg-mainE hover:bg-mainC px-4 transition-colors"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={openIndices.has(index)}
                    >
            <span className="text-[16px] md:text-[18px] font-bold uppercase  ">
              {item.title}
            </span>
                        <span className={`transform transition-transform duration-300 ${
                            openIndices.has(index) ? 'rotate-180' : 'rotate-0'
                        }`}>
              <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
              >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
                    </button>
                    <div
                        className={`overflow-hidden transition-all duration-300 ${
                            openIndices.has(index) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                        }`}
                    >
                        <div className="p-4 text-[14px] md:text-[16px] whitespace-pre-line bg-mainE">
                            {
                                item.image ? (
                                    <img
                                        className="max-h-[200px]"
                                        src={item.image}
                                    />) : null
                            }
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
