import {useEffect, useState} from "react";
import HardOffers from "./HardOffers";
import SoftOffers from "./SoftOffers";
import WeekPass from "./WeekPass";
import {useUser} from "../../UserContext";
import Cookies from "js-cookie";

export default function Wallet() {
    const [isAnimatingCoins, setIsAnimatingCoins] = useState<boolean>(false);
    const [isAnimatingRubies, setIsAnimatingRubies] = useState<boolean>(false);
    const [isUpdatedCoins, setIsUpdatedCoins] = useState<boolean>(false);
    const [isUpdatedRubies, setIsUpdatedRubies] = useState<boolean>(false);

    const [coins, setCoins] = useState<number>(0);
    const [rubies, setRubies] = useState<number>(0);

    async function getWallet() {
        const session_token = Cookies.get("c8_session_token");
        if (!session_token) return;

        try {
            const response = await fetch(
                `${process.env.API_URL}/account/${session_token}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.API_KEY as string,
                    },
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                setCoins(data.data?.wallet?.coins || 0);
                setRubies(data.data?.wallet?.rubies || 0);
            }
        } catch (error) {
            console.error("Error fetching wallet:", error);
        }
    }

    useEffect(() => {
        getWallet().catch(console.error);
    }, []);

    async function UpdateUserAmount(offerId: string, offerType: string) {
        const session_token = Cookies.get("c8_session_token");
        if (!session_token) return;

        try {
            const res = await fetch(`${process.env.API_URL}/buy`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.API_KEY as string,
                },
                body: JSON.stringify({
                    user_session: session_token,
                    offer_id: offerId,
                }),
            });

            const response = await res.json();
            const {data} = response;

            if (data?.coins !== undefined && offerType === "soft") {
                await updateCoins(data.coins);
                setRubies(data.rubies);
            }

            if (data?.rubies !== undefined && offerType === "hard") {
                await updateRubies(data.rubies);
            }
        } catch (error) {
            console.error("Error updating user amount:", error);
        }
    }

    async function updateCoins(coins: number) {
        setCoins(coins);
        setIsAnimatingCoins(true);
        setIsUpdatedCoins(true);
        const timeout = setTimeout(() => {
            setIsAnimatingCoins(false);
            setIsUpdatedCoins(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }

    async function updateRubies(rubies: number) {
        setRubies(rubies);
        setIsAnimatingRubies(true);
        setIsUpdatedRubies(true);
        const timeout = setTimeout(() => {
            setIsAnimatingRubies(false);
            setIsUpdatedRubies(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }

    const {user} = useUser();

    return (
        <div className="w-[100%]">
            <div className="flex flex-col items-center justify-center h-[40px] ml-[7px]">
                <p className="font-anton text-white">WALLET</p>
            </div>

            <div className="user-info-container">
                <div className="flex flex-row justify-center items-center mx-[7px]">
                    <img
                        className="w-[49px] h-[49px]"
                        src={user?.profile_pic}
                        alt={"profile pic"}
                    />
                    <p className="ml-[5px] text-white font-[400] text-[14px] font-anton leading-[22px]">
                        {user?.username}
                    </p>
                </div>

                <div className="flex flex-row justify-center items-center gap-[2px]">
                    <div className="w-[61px] h-[40px] rubies-coins-status">
                        <img
                            alt={"how many rubies the user has"}
                            className={`w-[29px] h-[17px] ${
                                isAnimatingRubies ? "rise-and-spin" : ""
                            }`}
                            src="../img/public/rubin_sm.png"
                        />
                        <p
                            className={`font-chakra rubies-coins-status-p ${
                                isUpdatedRubies ? "amount-increse-animation" : ""
                            }`}
                        >
                            {rubies}
                        </p>
                    </div>

                    <div className="w-[77px] h-[40px] rubies-coins-status">
                        <img
                            alt={"how many coins the user has"}
                            className={`w-[20px] h-[20px] ${
                                isAnimatingCoins ? "rise-and-spin" : ""
                            }`}
                            src="../img/public/coin_sm.png"
                        />
                        <p
                            className={`font-chakra rubies-coins-status-p ${
                                isUpdatedCoins ? "amount-increse-animation" : ""
                            }`}
                        >
              <span
                  className={`text-[14px] ${
                      isAnimatingCoins ? "rise-and-spin" : ""
                  }`}
              >
                {coins}
              </span>
                        </p>
                    </div>
                </div>
            </div>

            {/*TODO: The Shop is commented for this release*/}
            {/*<div className="buy-rubies font-anton">BUY RUBIES</div>*/}
            {/*<HardOffers updateRubies={updateRubies}/>*/}
            {/*<div*/}
            {/*    className="w-[100%] h-[30px] font-[400] text-[14px] font-anton leading-[15px] bg-gold text-white flex flex-col justify-center text-center">*/}
            {/*    BUY COINS*/}
            {/*</div>*/}
            {/*<SoftOffers*/}
            {/*    setIsAnimatingCoins={setIsAnimatingCoins}*/}
            {/*    totalRubies={rubies}*/}
            {/*    updateUserAmount={UpdateUserAmount}*/}
            {/*/>*/}
        </div>
    );
}
