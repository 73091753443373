import {useEffect} from 'react';
import {Accordion} from '../Accordion';

export const FAQ = () => {
    const faqItems = [
        {
            title: 'Who are we?',
            content: 'We are a growing community of 800,000+ cricket fans who live and breathe the game. Founded by cricket lovers, technologists and gamers, our platform delivers high-quality content, real-time s﻿ports data, live streaming commentary, videos, podcasts, and a growing library of fun, competitive mini-games.',
            // image: "../img/public/spinner_trivia.png" # This is an example if you need to add an image
        },
        {
            title: 'What kind of content do you provide?',
            content: `We offer a complete cricket experience, including:

                  ✅ Mini games – have fun & compete against friends in free to play cricket games.

                  ✅ Live match scores & stats – Stay updated in real-time.

                  ✅ Live streaming commentary – Ball-by-ball experts for the biggest games.

                  ✅ Exclusive videos & podcasts – Insights, analysis, and special features.

                  ✅ Social – Come join us on Facebook and Youtube.`
        },
        {
            title: 'Is this platform free to use?',
            content: 'Yes! Our live sports updates, commentary, and most content are free to access. Some games may require virtual coins, which can be won for free every day, or obtained using Rubies from our Shop.'
        },
        {
            title: 'What makes us different?',
            content: 'Unlike traditional sports platforms, we put fans at the center of everything we do. Our mission is to enhance your cricket experience with real-time updates, expert analysis, and interactive gaming, all in one place.'
        },
        {
            title: 'What are your mini-games?',
            content: 'Our mini-games let fans test their cricket knowledge and strategy in a fun, competitive way. Inspired by real-world sports betting, our games offer the thrill of predicting outcomes and competing against others—but without gambling or real-money stakes.'
        },
        {
            title: 'Are the games gambling?',
            content: 'No! Our games are designed to be fun. entertaining and competitive. While users can buy Rubies and convert them into virtual coins to play, no real-money winnings or withdrawals are possible. This makes our platform a safe, risk-free, and legal alternative to traditional sports betting.'
        },
        {
            title: 'How do Rubies and virtual coins work?',
            content: `Rubies can be purchased in our Shop and converted into virtual coins.

                Virtual coins are stored safely in your wallet, ready to be used to play our mini-games.

                Users compete, cl﻿imb leaderboards, and earn bragging rights—but cannot withdraw real money.`
        },
        {
            title: `Why can't I buy Rubies?`,
            content: 'Our Shop and ways to pay are available in India only at the moment. We plan to offer more payment options and roll out to more countries in the coming months.'
        },
        {
            title: 'How can I join the community?',
            content: 'Easy! Simply sign up on our platform, follow us on social media, and start engaging with fellow cricket lovers. Whether you’re here for the latest match updates or looking to challenge your friends in a game, you’re part of the squad!'
        },
        {
            title: 'Where can I get support or ask more questions?',
            content: `If you have any questions or need help, feel free to reach out via:

                📩 Visit our Contact Us page

                📢 Facebook

                Ready to experience cricket like never before? Join our community today! 🏏🔥`
        }
    ];

    useEffect(() => {
        document.title = "FAQs | Cricket8";
    }, []);

    return (
        <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px] p-[10px] font-chakra">
            <h1 className="text-[30px] md:text-[55px] pl-[5px]">SUPPORT</h1>

            <div className="mt-[30px] font-chakra text-[#fff] ">
                <Accordion items={faqItems}/>
            </div>
        </div>
    );
};

export default FAQ
